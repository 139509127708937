<template>
  <div class="layout_common sale_rank">
    <div class="main_box">
      <div class="main_box_con">
        <div class="search_flex" style="margin-bottom:10px">
          <div class="search_left">
            <el-date-picker
              v-model="day"
              type="date"
              size="small"
              :clearable="false"
              placeholder="选择日期"
            >
            </el-date-picker>
            <selectComp
              placeholder="角色"
              :clearable="false"
              :hval="roleType"
              :data="roleTypeList"
              @change="roleTypeChange"
            />
            <btnGroup @search="search" @reset="reset" />
          </div>
        </div>
        <div v-loading="loading">
          <ul class="stat">
            <li>
              <div>今日上门单</div>
              <div class="num">{{ todayVisitCount }}</div>
              <div>本月累计：{{ visitCount }}</div>
            </li>
            <li>
              <div>今日远程单</div>
              <div class="num">{{ todayRemoteCount }}</div>
              <div>本月累计：{{ remoteCount }}</div>
            </li>
            <li>
              <div>今日工单</div>
              <div class="num">{{ todayOrderCount }}</div>
              <div>本月累计：{{ orderCount }}</div>
            </li>
          </ul>
          <ul class="stat_echarts">
            <li>
              <barEchart
                title="今日售后单王"
                :propData="todayData"
                barColor="#FF8F36"
                id1="today_rank_box"
                id2="today_rank_Echarts"
              />
            </li>
            <li>
              <barEchart
                title="本周售后单王"
                :propData="weekData"
                barColor="#A35CFF"
                id1="week_rank_box"
                id2="week_rank_Echarts"
              />
            </li>
            <li>
              <barEchart
                title="本月售后单王"
                :propData="monthData"
                barColor="#0BB0FF"
                id1="month_rank_box"
                id2="month_rank_Echarts"
              />
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import barEchart from '../components/rankBar'
import { saleRankData } from '@/api'
import { formatDate } from '@/utils/common'
export default {
  components: {
    barEchart
  },
  data () {
    return {
      loading: false,
      day: new Date(),
      roleType: 1,
      roleTypeList: [
        { label: '售后', value: 1 },
        { label: '调度', value: 2 },
        { label: '客服', value: 3 }
      ],
      statData: {}, // 总览的数据
      todayOrderCount: '',
      orderCount: '',
      todayRemoteCount: '',
      remoteCount: '',
      todayVisitCount: '',
      visitCount: '',
      todayData: [],
      weekData: [],
      monthData: []
    }
  },
  mounted () {
    this.getData()
  },
  methods: {
    getData () {
      const params = {
        time: formatDate(this.day),
        roleType: this.roleType
      }
      this.loading = true
      saleRankData(params).then(res => {
        this.loading = false
        const {
          todayOrderCount,
          orderCount,
          todayRemoteCount,
          remoteCount,
          todayVisitCount,
          visitCount,
          dayList,
          monthList,
          weekList
        } = res.data
        this.todayVisitCount = todayVisitCount
        this.visitCount = visitCount
        this.todayOrderCount = todayOrderCount
        this.orderCount = orderCount
        this.todayRemoteCount = todayRemoteCount
        this.remoteCount = remoteCount
        this.todayData = dayList
        this.weekData = weekList
        this.monthData = monthList
      })
    },
    roleTypeChange (val) {
      this.roleType = val
    },
    search () {
      this.getData()
    },
    reset () {
      this.roleName = '售后'
      this.day = new Date()
      this.roleType = 1
      this.getData()
    }
  }
}
</script>
<style lang="scss" scoped>
.sale_rank {
  .stat {
    display: flex;
    justify-content: space-around;
    li {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex: 1;
      box-sizing: border-box;
      text-align: center;
      // width: 234px;
      height: 160px;
      margin-right: 30px;
      border-radius: 4px;
      padding: 24px;
      font-size: 16px;
      color: #fff;
      &:nth-child(1) {
        background: linear-gradient(45deg, #ff8f36 0%, #f8bd21 100%);
        box-shadow: 0px 2px 8px 0px rgba(238, 42, 0, 0.33);
      }
      &:nth-child(2) {
        background: linear-gradient(45deg, #a35cff 0%, #bf6cff 100%);
        box-shadow: 0px 2px 8px 0px rgba(12, 30, 199, 0.33);
      }
      &:nth-child(3) {
        background: linear-gradient(45deg, #0bb0ff 0%, #06d7f2 100%);
        box-shadow: 0px 2px 8px 0px rgba(4, 182, 244, 0.33);
        margin-right: 0;
      }
      .num {
        font-size: 34px;
      }
    }
  }
  .stat_echarts {
    // height: calc(100% - 220px);
    height: 500px;
    display: flex;
    li {
      flex: 1;
    }
  }
}
</style>
