<template>
  <div :id="id1" style="width:100%;height:100%">
    <div :id="id2" style="width:100%;height:100%"></div>
    <notData v-if="propData.length == 0" :title="title" />
  </div>
</template>
<script>
export default {
  props: {
    propData: {
      type: Array,
      default: () => []
    },
    title: {
      type: String,
      default: ''
    },
    barColor: {
      type: String,
      default: ''
    },
    id1: {
      type: String,
      default: ''
    },
    id2: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      data: []
    }
  },
  watch: {
    propData: {
      handler (n) {
        this.data = n.map(item => ({
          name: item.userName,
          value: item.orderCount
        }))
        this.init()
      },
      deep: true
    }
  },
  mounted () {
    window.addEventListener('resize', this.resizeCharts, true)
    this.$erd(this.id1, this.id2)
  },
  destroyed () {
    window.removeEventListener('resize', this.resizeCharts, true)
  },
  methods: {
    resizeCharts () {
      const myChart = this.$echarts.init(document.getElementById(this.id2))
      myChart.resize()
    },
    init () {
      const myChart = this.$echarts.init(
        document.getElementById(this.id2)
      )
      let dataY = []
      let dataX = []
      this.data = this.data.reverse()
      if (this.data.length > 0) {
        dataY = this.data.map(item => item.name)
        dataX = this.data.map(item => item.value)
      }
      const option = {
        title: {
          text: this.title,
          left: '10%',
          top: '5%'
        },
        // tooltip: {
        //   trigger: 'axis',
        //   axisPointer: {
        //     type: 'shadow'
        //   }
        // },
        grid: {
          left: '3%',
          right: '15%',
          bottom: '0',
          top: '60',
          containLabel: true
        },
        xAxis: [
          {
            type: 'value',
            axisTick: {
              // 坐标轴刻度相关设置。
              show: false
            },
            axisLine: {
              // 坐标轴轴线相关设置
              show: false,
              lineStyle: {
                color: '#95A3B6',
                opacity: 1
              }
            },
            splitLine: {
              // 坐标轴在 grid 区域中的分隔线。
              show: true,
              lineStyle: {
                type: 'dashed',
                color: '#ddd'
              }
            },
            axisLabel: {
              show: false
            }
          }
        ],
        yAxis: [
          {
            type: 'category',
            axisTick: {
              show: false
            },
            data: dataY,
            axisLine: {
              show: false,
              lineStyle: {
                color: '#95A3B6'
              }
            },
            splitLine: {
              show: false,
              lineStyle: {
                type: 'dashed',
                color: '#ddd'
              }
            },
            axisLabel: {
              color: '#000',
              fontSize: 14
            }
          }
        ],
        series: [
          {
            // name: this.title.slice(0, 2),
            type: 'bar',
            barMaxWidth: '40',
            itemStyle: {
              normal: {
                color: this.barColor
              }
            },
            label: {
              show: true,
              position: 'right',
              textStyle: {
                color: '#000'
              }
            },
            data: dataX
          }
        ]
      }
      myChart.setOption(option)
    }
  }
}
</script>
